<template>
  <div>
    <headeBar ref="header" title="生成二维码" @click-left="newAppBack" />
    <van-form validate-first @submit="sharePoster">
      <!-- 通过 pattern 进行正则校验 -->
      <van-field
        v-model="webUrl"
        name="webUrl"
        label="活动地址"
        placeholder="请输入网址"
        :rules="[{ required: true, message: '请输入' }]"
      />
      <van-field
        v-model="size"
        name="size"
        label="二维码大小"
        placeholder="默认240"
        :rules="[{ required: true, message: '请输入' }]"
      />
      <div style="margin: 16px;">
        <van-button round block type="info" native-type="submit">生成</van-button>
      </div>
    </van-form>
    <div style="padding:8px 16px;font-size: 14px;color: red">注：二维码有效期为1个月</div>
  
    <img style="width: 60vw;height: 60vw;display: block;margin: 16px auto;" v-if="userPoster" class="poster-code-img" :src="'data:image/png;base64,' + userPoster" alt="">
    
  </div>
</template>

<script>
import {newAppBack} from "@/lib/appMethod";
import headeBar from '@/components/app/headBar';
import { getParam } from "@/lib/utils"
export default {
  name: "createQrcode",
  components: {headeBar},
  data (){
    return {
      
      userPoster: '',
      webUrl: '',
      size: 240,
      extendJson: '',
      radio: 1,
    }
  },
  created() {
  
  },
  methods:{
    newAppBack,
    sharePoster() {
      if(this.userId && this.token){
        this.param = {
          userId: this.userId,
          token: this.token
        }
      }
      let scene = {webUrl: this.webUrl.split('?')[0]}
      let extendData = getParam(this.webUrl)
      Object.assign(scene, extendData)
      console.log(this.base);
      console.log(this.baseURLApp);
      this.$axios.post(`${this.baseURLApp}/qrCode/createOrdinaryCode/qrCode`, {
        width: this.size,
        height: this.size,
        webUrl: "https://web.chaolu.com.cn/#/ordinary_004001",
        scene: JSON.stringify(scene)
      }).then(res => {
        this.userPoster = res.data.qrCode
      })
    },
  }
}
</script>

<style scoped>

</style>
